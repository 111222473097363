import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import env from "../../../../config/env";
import { useStore } from "../../../../store";
import { ToastContainer, toast } from "react-toastify";

type Option = {
  value: string;
  label: string;
};

export default function PersonalInfo() {
  const navigate = useNavigate();
  const { userStore } = useStore();
  let user = userStore.user;

  const [fullName, setFullName] = useState<string>(user?.fullName || "");
  const [bio, setBio] = useState<string>(user?.bio || "");
  const [phone, setPhone] = useState<string>(user?.phone || "");
  const [dob, setDob] = useState<string>(
    user?.birthday.toString().substr(0, 10) || ""
  );
  const [interests, setInterests] = useState<string[]>([]);
  const [country, setCountry] = useState<Option | null>({
    value: user?.country,
    label: user?.country,
  });
  const [dragging, setDragging] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File>();
  const pictureUploadRef = useRef<HTMLInputElement>(null);

  const handlePictureUpload = (): void => {
    if (pictureUploadRef.current && pictureUploadRef.current.files) {
      const file = pictureUploadRef.current.files[0];
      setProfilePicture(file);
    }
  };

  const triggerFileInput = (): void => {
    pictureUploadRef.current?.click();
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      setProfilePicture(file);
    }
  };

  const onPlaceSelected = async (value: Option | null) => {
    setCountry(value);
  };

  const handleSave = async () => {
    const updatedUser = {
      fullName: fullName,
      bio: bio,
      birthday: new Date(dob),
      phone: phone,
      // interests: interests,
      country: country?.label,
      image: profilePicture,
    };

    if (profilePicture) {
      try {
        const response = await userStore.uploadPicture(profilePicture);
        if (response) {
          updatedUser.image = response;

          await updateUserProfile(updatedUser);
        } else {
          toast.error(
            "Error uploading new profile picture. Please try uploading a different picture type"
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      await updateUserProfile(updatedUser);
    }
  };

  const updateUserProfile = async (updatedUser: any) => {
    await userStore
      .updateProfile(user.id, updatedUser)
      .then((res) => {
        toast.success("Profile Updated Successfully");
      })
      .catch((err) => {
        toast.error("Error Updating Profile");
        console.log(err);
      });
  };

  return (
    <form className="personal-info">
      <ToastContainer theme="dark" />
      <div className="inputs">
        <div className="left-column">
          <div className="field">
            <label htmlFor="name">Full name</label>
            <div className="input-field">
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
          </div>

          <div className="field">
            <label htmlFor="bio">Bio</label>
            <div className="input-field">
              <textarea
                className="bio"
                id="bio"
                placeholder="Tell us about yourself"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              />
            </div>
          </div>

          <div className="field">
            <label htmlFor="picture">Profile Picture</label>
            <div className="input-field">
              <div
                className="upload-picture"
                style={{
                  backgroundColor: dragging ? "lightblue" : "#f5f5f5",
                }}
                onClick={triggerFileInput}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragEnter}
                onDrop={handleDrop}
              >
                <div className="default-picture">
                  {dragging ? (
                    <div>Drop Here</div>
                  ) : profilePicture ? (
                    <img src={URL.createObjectURL(profilePicture)} alt="" />
                  ) : (
                    <>
                      <div>+</div>
                      <div>Upload</div>
                    </>
                  )}
                </div>
              </div>
              <input
                className="fileInput"
                ref={pictureUploadRef}
                type="file"
                id="picture"
                onChange={handlePictureUpload}
              />
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="field">
            <label htmlFor="dob">Birth Date</label>
            <div className="input-field">
              <div className="input">
                <input
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="field">
            <label htmlFor="phone">Phone number</label>
            <div className="input-field">
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>

          <div className="field">
            <label htmlFor="loaction">Location</label>
            <div className="input-field">
              <div className="input">
                <GooglePlacesAutocomplete
                  apiKey={env.GOOGLE_MAPS_API_KEY}
                  selectProps={{
                    onChange: onPlaceSelected,
                    placeholder: "Your city",
                    value: country || undefined,
                    styles: {
                      input: (provided) => ({
                        ...provided,
                        height: "100%",
                      }),

                      dropdownIndicator: (provided) => ({
                        display: "none",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                      }),
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #cad4e0",
                        borderRadius: "8px",
                        height: "3rem",
                      }),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="buttons">
        <Button
          text="Cancel"
          type="primary"
          customColor="#373737"
          width="400px"
          preventDefault={true}
          onClick={() => navigate("/")}
        />
        <Button
          text="Save"
          type="primary"
          width="400px"
          preventDefault={true}
          onClick={handleSave}
        />
      </div>
    </form>
  );
}
