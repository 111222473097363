import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ConfirmationPopup, Input } from "../../../../components";
import { useStore } from "../../../../store";
import { ToastContainer, toast } from "react-toastify";
import {
  validateEmail,
  validatePassword,
  validateName,
  validateConfirmPassword,
} from "../../../../utils/validation";

export default function PersonalInfo({ user }: any) {
  const navigate = useNavigate();
  const { userStore } = useStore();

  const [email, setEmail] = useState<string>(user?.email);
  const [password, setPassword] = useState<string>("");
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const handleSave = () => {
    setEmail(email.trim());
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleLogout = () => {
    userStore.logout();
    navigate("/login");
  };

  const handleConfirmation = async () => {
    if (password !== "" || email !== "") {
      let updateProfileBody: any = {};
      if (password !== "") {
        updateProfileBody["password"] = password;
      }
      if (email !== "") {
        updateProfileBody["email"] = email;
      }

      await userStore
        .updateProfile(user?.id, updateProfileBody)
        .then((result) => {
          handleLogout();
        })
        .catch((err) => {
          toast.error("Error updating Email/password");
        });
    }
  };

  return (
    <form className="personal-info">
      <ToastContainer theme="dark" />
      <div className="inputs">
        <div className="left-column">
          <div className="field">
            <label htmlFor="email">Email</label>
            <div className="input-field">
              <div className="input">
                <Input
                  type="text"
                  label="Email"
                  value={email}
                  validation={validateEmail}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="field">
            <label htmlFor="password">Password</label>
            <div className="input-field">
              <div className="input">
                <Input
                  type="password"
                  label="Password"
                  value={password}
                  validation={password != "" ? validatePassword : undefined}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="right-column"></div>
      </div>

      <div className="buttons">
        <Button
          text="Cancel"
          type="primary"
          customColor="#373737"
          width="400px"
          preventDefault={true}
          onClick={() => navigate("/")}
        />
        <Button
          text="Save"
          type="primary"
          width="400px"
          preventDefault={true}
          onClick={handleSave}
          isDisabled={
            !validateEmail(email) ||
            (password != "" && !validatePassword(password))
          }
        />
      </div>

      <ConfirmationPopup
        title="Edit email address/Password"
        text="To edit your email or password you must confirm your current password."
        isOpen={isPopupOpen}
        requirePassword
        close={closePopup}
        confirm={handleConfirmation}
      />
    </form>
  );
}
