import React from "react";

import styles from "./blogs.module.css";
import Blog from "../BlogItem";

const Blogs: React.FC = () => {
    return (
        <div className={styles.blogs_container}>
            <div className={styles.blogs}>
                <h2>Our recent blogs</h2>

                <div className={styles.blogs_list}>
                    <Blog
                        blog={{
                            title: "Cyber Security certifications approved by the US Department of Defense",
                            description: "We’re thrilled to announce a groundbreaking development in our partnership with CertNexus!",
                            date: "May 23, 2023",
                            image: "https://wemag.wecode.land/wp-content/uploads/2024/01/Bannie%CC%80re-de-blog-6-2048x1152.png",
                            link: "https://wemag.wecode.land/cyber-security-certifications-by-certnexus-approved-by-the-us-defense-department"
                        }}
                    />
                    <Blog
                        fill
                        blog={{
                            title: "Teach with WeCode: Sharing Your Expertise with the World",
                            description: "WeCode offers a unique opportunity for tech experts to become instructors and share their expertise globally. Here’s a brief guide on how to join WeCode and make a positive impact on Tech enthusiasts.",
                            date: "January 14, 2024",
                            image: "https://wemag.wecode.land/wp-content/uploads/2024/01/Bannie%CC%80re-de-blog-1024x576.png",
                            link: "https://wemag.wecode.land/tech-with-us"
                        }}
                    />
                    <Blog
                        blog={{
                            title: "List your Space",
                            description: "Are you the manager of a coworking space, training facility, or any versatile venue capable of hosting coding courses...",
                            date: "August 2, 2023",
                            image: "https://wemag.wecode.land/wp-content/uploads/2023/08/Banniere-de-blog-1-1024x576.png",
                            link: "https://wemag.wecode.land/list-your-space"
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Blogs;