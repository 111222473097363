import React, { useEffect, useRef } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import env from "../../../config/env";

import styles from "./popup.module.css";
import { Button, Input } from "../..";
import { useStore } from "../../../store";
import {
  validateEmail,
  validateName,
  validatePassword,
} from "../../../utils/validation";
import { useGoogleLogin } from "@react-oauth/google";

interface IProps {
  course: string;
  isOpen: boolean;
  closeModal?: () => void;
  type: "course" | "cohort";
}

const Popup: React.FC<IProps> = ({ type, isOpen, course, closeModal }) => {
  const LinkedInApi = {
    clientId: env.LINKEDIN_CLIENT_ID,
    redirectUrl: env.LINKEDIN_REDIRECT_URL,
    oauthUrl: env.LINKEDIN_OAUTH_URL,
    scope: env.LINKEDIN_SCOPE,
    state: env.LINKEDIN_STATE,
  };
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();

  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [fullName, setFullName] = React.useState<string>("");
  const [selected, setSelected] = React.useState<"login" | "register">("login");

  const isLinkedInAuthRef = useRef<boolean>(false);

  useEffect(() => {
    isLinkedInAuthRef.current = false;

    if (!isLinkedInAuthRef.current) {
      if (window.opener && window.opener !== window) {
        const code = getCodeFromWindowURL(window.location.href);
        window.opener.postMessage({ type: "code", code: code }, "*");

        window.close();
      }

      window.addEventListener("message", handlePostMessage);
    }

    return () => {
      window.removeEventListener("message", handlePostMessage);
      isLinkedInAuthRef.current = true;
    };
  });

  const navigateToCompleteRegister = () => {
    const user = {
      email: email,
      fullName: fullName,
      password: password,
    };
    userStore.setUserData(user);
    navigate("/complete-register");
  };

  const login = async () => {
    userStore
      .login(email, password)
      .then(async (token) => {
        if (token && token !== null) {
          if (type === "course") {
            await courseStore.lookForSessions(course);
            navigate("/search");
          } else if (type === "cohort") {
            navigate(`/payment/${courseStore.selectedCourse}`);
          }
        } else {
          toast("Invalid email or password!");
        }
      })
      .catch((err) => {
        toast("Invalid email or password!");
      });
  };

  // ########## START GOOGLE AUTH LOGIC ##########
  const handleGoogleAuth = () => {
    if (selected === "login") {
      googleSignUp();
    } else {
      googleLogin();
    }
  };

  const googleSignUp = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleSuccess(tokenResponse),
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleLogin(tokenResponse),
  });

  const handleGoogleSuccess = async (response: any) => {
    try {
      const googleToken = response.access_token;
      const res = await userStore.signUpWithGoogle(googleToken);
      console.log("res", res);

      if (res) {
        navigate("/");
      } else {
        toast("Google Sign up failed!");
      }
    } catch (error: any) {
      if (error.response.data.error.code === "USER_EXISTS") {
        handleGoogleLogin(response);
      } else {
        toast(error.response.data.error.message);
      }
    }
  };

  const handleGoogleLogin = async (response: any) => {
    try {
      const token = response.access_token;
      const res = await userStore.loginWithGoogle(token);

      if (res) {
        if (type === "course") {
          await courseStore.lookForSessions(course);
          navigate("/search");
        } else if (type === "cohort") {
          navigate(`/payment/${courseStore.selectedCourse}`);
        }
      } else {
        toast("Google login failed!");
      }
    } catch (error: any) {
      console.log("error", error);

      toast(error.response.data.error.message);
    }
  };
  // ########## END GOOGLE AUTH LOGIC ##########

  // ########## START LINKEDIN AUTH LOGIC #########
  const showPopup = () => {
    const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
    const oauthUrlComplete = `${oauthUrl}&client_id=${encodeURIComponent(
      clientId!
    )}&scope=${encodeURIComponent(
      scope!
    )}&state=${state}&redirect_uri=${redirectUrl}`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrlComplete,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  const getCodeFromWindowURL = (url: any) => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get("code");
  };

  const handlePostMessage = (event: any) => {
    if (event.data.type === "code" && !isLinkedInAuthRef.current) {
      isLinkedInAuthRef.current = true;
      const { code } = event.data;
      if (selected === "login") {
        handleLinkedInLoginSuccess(code);
      }
      if (selected === "register") {
        handleLinkedInSignUpSuccess(code);
      }
    }
  };

  const handleLinkedInLoginSuccess = async (code: any) => {
    try {
      const linkedinToken = code;

      const res = await userStore.loginWithLinkedIn(linkedinToken);
      if (res) {
        if (type === "course") {
          await courseStore.lookForSessions(course);
          navigate("/search");
        } else if (type === "cohort") {
          navigate(`/payment/${courseStore.selectedCourse}`);
        }
      } else {
        toast("LinkedIn Sign in failed!");
      }
    } catch (error: any) {
      console.log("error", error);

      toast(error.response.data.error.message);
    }
  };
  const handleLinkedInSignUpSuccess = async (code: any) => {
    try {
      const linkedinToken = code;

      const res = await userStore.signUpWithLinkedIn(linkedinToken);
      if (res) {
        /*  */
        if (type === "course") {
          await courseStore.lookForSessions(course);
          navigate("/search");
        } else if (type === "cohort") {
          navigate(`/payment/${courseStore.selectedCourse}`);
        }
      } else {
        toast("LinkedIn Sign in failed!");
      }
    } catch (error: any) {
      console.log("error", error);

      toast(error.response.data.error.message);
    }
  };
  // ########## END LINKEDIN AUTH LOGIC

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
      <ToastContainer theme="dark" />
      <div
        className={styles.header}
        style={{ backgroundImage: "url('/images/popup.png')" }}
      >
        <h1 className={styles.header_title}>Please Log In to continue</h1>
        <span className={styles.header_sub_title}>
          Want to improve your skills ? Start with WeCode
        </span>
      </div>
      <div className={styles.header_navbar_container}>
        <div className={styles.header_navbar}>
          <div
            onClick={() => setSelected("login")}
            className={
              selected === "login"
                ? styles.selected_switch_btn
                : styles.switch_btn
            }
          >
            <span className={styles.switch_btn_text}>Login</span>
          </div>
          <div
            onClick={() => setSelected("register")}
            className={
              selected === "register"
                ? styles.selected_switch_btn
                : styles.switch_btn
            }
          >
            <span className={styles.switch_btn_text}>Register</span>
          </div>
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.social_container}>
          <Button
            type="tertiary"
            text="Continue with Google"
            onClick={() => handleGoogleAuth()}
            icon="/images/icons/google-colored.svg"
          />
          <Button
            type="tertiary"
            text="Continue with LinkedIn"
            onClick={() => showPopup()}
            icon="/images/icons/linkedin-colored.svg"
          />
        </div>

        <hr className={styles.divider} />

        <div className={styles.form_container}>
          {selected === "login" ? (
            <div className={styles.form}>
              <Input
                label="Email"
                value={email}
                validation={validateEmail}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                label="Password"
                type="password"
                value={password}
                validation={validatePassword}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="primary"
                text="Login"
                onClick={() => login()}
                isDisabled={
                  !validateEmail(email) || !validatePassword(password)
                }
              />
            </div>
          ) : null}

          {selected === "register" ? (
            <div className={styles.form}>
              <Input
                label="Fullname"
                value={fullName}
                validation={validateName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <Input
                label="Email"
                value={email}
                validation={validateEmail}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                label="Password"
                type="password"
                value={password}
                validation={validatePassword}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="primary"
                text="Sign Up"
                onClick={() => navigateToCompleteRegister()}
                isDisabled={
                  !validateEmail(email) ||
                  !validatePassword(password) ||
                  !validateName(fullName)
                }
              />
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "8px",
    border: "1px solid #ccc",
    transform: "translate(-50%, -50%)",
  },
};

export default observer(Popup);
