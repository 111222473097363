"use client";
import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import "../InstructorUpgrade/styles.css";
import "react-international-phone/style.css";
import { Input, Select, Button, Navbar } from "../../../components";
import MultiSelectInput from "../../../components/shared/MultiSelectInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import { useStore } from "../../../store";
import { useNavigate } from "react-router-dom";

function HostUpgrade() {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();

  const [numberOfPlaces, setNumberOfPlaces] = useState<string>("");
  const [bio, setBio] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [equipments, setEquipments] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [logoDragging, setLogoDragging] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File>();
  const [logo, setLogo] = useState<File>();
  const [files, setFiles] = useState<File[]>([]);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const pictureUploadRef = useRef<HTMLInputElement>(null);
  const logoUploadRef = useRef<HTMLInputElement>(null);
  const fileUploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("login");
      }
    });
  }, []);

  const handlePictureUpload = (element: string): void => {
    if (element === "logo") {
      if (
        logoUploadRef.current?.files &&
        logoUploadRef.current.files.length > 0
      ) {
        const file = logoUploadRef.current.files[0];
        setLogo(file);
      }
    } else {
      if (pictureUploadRef.current && pictureUploadRef.current.files) {
        const file = pictureUploadRef.current.files[0];
        setProfilePicture(file);
      }
    }
  };
  const triggerFileInput = (element: string): void => {
    if (element === "logo") {
      logoUploadRef.current?.click();
    } else {
      pictureUploadRef.current?.click();
    }
  };
  const handleDragEnter = (e: React.DragEvent, element: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (element === "logo") {
      setLogoDragging(true);
    } else {
      setDragging(true);
    }
  };
  const handleDragLeave = (e: React.DragEvent, element: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (element === "logo") {
      setLogoDragging(false);
    } else {
      setDragging(false);
    }
  };
  const handleDrop = (e: React.DragEvent, element: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (element === "logo") {
      setLogoDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        setLogo(file);
      }
    } else {
      setDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        setProfilePicture(file);
      }
    }
  };
  const handleAttachFile = (e: any) => {
    const newFiles: File[] = Array.from(e.target.files);
    const existingFileNames = files.map((file) => file.name);
    const filteredNewFiles = newFiles.filter(
      (file: File) => !existingFileNames.includes(file.name)
    );
    setFiles([...files, ...filteredNewFiles]);
  };
  const triggerAttachFile = (): void => {
    fileUploadRef.current?.click();
  };
  const CustomInput = React.forwardRef<any, any>(
    ({ value, onClick, placeholder }, ref) => (
      <input
        className="custom-input"
        onClick={onClick}
        ref={ref}
        placeholder={placeholder}
        value={value}
        onChange={() => {}}
      />
    )
  );

  const onRequestUpgrade = async () => {
    try {
      const response = await userStore.sendUpgradeRequest(
        "host",
        userStore.user.id
      );
      response.STATUS === 200
        ? toast.success(response.MESSAGE)
        : toast.error(response.MESSAGE);
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="main">
      <ToastContainer theme="dark" />
      <Navbar isTransparent />
      <h1 className="title">Host Upgrade</h1>
      <form>
        <section>
          <div className="inputs">
            <div className="left-column">
              <div className="field">
                <label htmlFor="picture">Profile Picture</label>
                <div className="input-field">
                  <div
                    className="upload-picture"
                    style={{
                      backgroundColor: dragging ? "lightblue" : "#f5f5f5",
                    }}
                    onClick={() => triggerFileInput("profilePicture")}
                    onDragEnter={(e) => handleDragEnter(e, "profilePicture")}
                    onDragLeave={(e) => handleDragLeave(e, "profilePicture")}
                    onDragOver={(e) => handleDragEnter(e, "profilePicture")}
                    onDrop={(e) => handleDrop(e, "profilePicture")}
                  >
                    <div className="default-picture">
                      {dragging ? (
                        <div>Drop Your Picture Here</div>
                      ) : profilePicture ? (
                        <img src={URL.createObjectURL(profilePicture)} alt="" />
                      ) : (
                        <>
                          <div>+</div>
                          <div>Upload</div>
                        </>
                      )}
                    </div>
                  </div>
                  <input
                    className="fileInput"
                    ref={pictureUploadRef}
                    type="file"
                    id="picture"
                    onChange={() => handlePictureUpload("profilePicture")}
                  />
                </div>
              </div>
            </div>
            <div className="right-column">
              <div className="field">
                <label htmlFor="bio">Bio</label>
                <div className="input-field">
                  <textarea
                    className="bio"
                    id="bio"
                    placeholder="Tell us about yourself"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <h2>Classroom Information</h2>
          <div className="inputs">
            <div className="left-column">
              <div className="field">
                <label htmlFor="logo">Logo (optional)</label>
                <div className="input-field">
                  <div
                    className="upload-picture"
                    style={{
                      backgroundColor: logoDragging ? "lightblue" : "#f5f5f5",
                    }}
                    onClick={() => triggerFileInput("logo")}
                    onDragEnter={(e) => handleDragEnter(e, "logo")}
                    onDragLeave={(e) => handleDragLeave(e, "logo")}
                    onDragOver={(e) => handleDragEnter(e, "logo")}
                    onDrop={(e) => handleDrop(e, "logo")}
                  >
                    <div className="default-picture">
                      {logoDragging ? (
                        <div>Drop Your Picture Here</div>
                      ) : logo ? (
                        <img src={URL.createObjectURL(logo)} alt="" />
                      ) : (
                        <>
                          <div>+</div>
                          <div>Upload</div>
                        </>
                      )}
                    </div>
                  </div>
                  <input
                    className="fileInput"
                    ref={logoUploadRef}
                    type="file"
                    id="logo"
                    onChange={() => handlePictureUpload("logo")}
                  />
                </div>
              </div>
              <div className="field">
                <label htmlFor="places">Number of places (Maximum)</label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="number"
                      label=""
                      fullWidth
                      value={numberOfPlaces}
                      onChange={(e) => setNumberOfPlaces(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="date">Available date</label>
                <div className="input-field">
                  <div className="input">
                    <div className="date-range-picker">
                      <DatePicker
                        selected={startDate}
                        onChange={(date: Date | null) =>
                          setStartDate(date ?? undefined)
                        }
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="Start date"
                        dateFormat="dd-MM-yyyy"
                        customInput={<CustomInput placeholder="Start date" />}
                      />
                      <span className="arrow">→</span>
                      <DatePicker
                        selected={endDate}
                        onChange={(date: Date | null) =>
                          setEndDate(date ?? undefined)
                        }
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        placeholderText="End date"
                        dateFormat="dd-MM-yyyy"
                        customInput={<CustomInput placeholder="End date" />}
                      />
                      <img src="/images/date.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="date">Available time</label>
                <div className="input-field">
                  <div className="input">
                    <div className="date-range-picker">
                      <input
                        className="custom-input"
                        type="number"
                        required
                        max={23}
                        min={0}
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        placeholder="Start hour"
                      />
                      <span className="arrow">→</span>
                      <input
                        className="custom-input"
                        type="number"
                        required
                        max={23}
                        min={0}
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        placeholder="End hour"
                      />
                      <img src="/images/clock.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-column">
              <div className="field">
                <label htmlFor="description">Description</label>
                <div className="input-field">
                  <textarea
                    className="bio"
                    id="description"
                    placeholder="Tell us about this classroom"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <MultiSelectInput
                  text="Available Equipments"
                  name="equipments"
                  onValueChange={setEquipments}
                />
              </div>

              <div className="field">
                <label>Classroom Pictures</label>
                <div className="input-field" id="files">
                  <div className="files">
                    {files.map((file, index) => (
                      <div className="file" key={index}>
                        <img
                          className="document"
                          src="/images/document.png"
                          alt="doc"
                        />
                        <p>{file.name}</p>
                        <img
                          className="trash"
                          src="/images/trash.png"
                          alt="trash"
                          onClick={() =>
                            setFiles(files.filter((f, i) => i !== index))
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <input
                    className="fileInput"
                    type="file"
                    ref={fileUploadRef}
                    multiple
                    onChange={(e) => handleAttachFile(e)}
                  />
                  <Button
                    text="+ Attach file"
                    type="secondary"
                    width="200px"
                    customColor="rgb(117, 116, 116)"
                    onClick={triggerAttachFile}
                    preventDefault
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="buttons">
          <Button
            text="Cancel"
            type="primary"
            customColor="#373737"
            width="400px"
            preventDefault
            onClick={() => navigate("/")}
          />
          <Button
            text="Save"
            type="primary"
            width="400px"
            preventDefault
            onClick={onRequestUpgrade}
          />
        </div>
      </form>
    </div>
  );
}
export default observer(HostUpgrade);
