import React from "react";
import { Link } from "react-router-dom";

import styles from "./footer.module.css";

const Footer: React.FC = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer_container}>
                <div className={styles.larger_column}>
                    <img
                        src="/images/logo-white.png"
                        alt="Logo"
                        width={145}
                        height={30}
                    />
                    <p>
                        WeCode is an AI-powered platform that connects individuals worldwide with expert-led learning groups focused on IT skills from industry-leading companies.
                    </p>
                </div>

                <div className={styles.column}>
                    <span className={styles.title}>Company</span>
                    
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={styles.subtitle}
                                href="https://wemag.wecode.land/about-us"
                            >
                                    Who we are
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={styles.subtitle}
                                href="https://wemag.wecode.land/what-we-offer"
                            >
                                    What we offer
                            </a>
                        </li>
                        <li>
                            <Link
                                to="/"
                                className={styles.subtitle}
                            >
                                    For individuals
                            </Link>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={styles.subtitle}
                                href="https://wemag.wecode.land/enterprise"
                            >
                                    For Enterprise
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={styles.subtitle}
                                href="https://wemag.wecode.land/government"
                            >
                                    For Government
                            </a>
                        </li>
                    </ul>
                </div>

                <div className={styles.column}>
                    <span className={styles.title}>People</span>
                    
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={styles.subtitle}
                                href="https://wemag.wecode.land/teach-with-us"
                            >
                                    Instructors
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://wemag.wecode.land/list-your-space"
                                className={styles.subtitle}
                            >
                                    Space owners
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={styles.subtitle}
                                href="https://wemag.wecode.land/code-de-conduite"
                            >
                                    Code of Conduct
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://wemag.wecode.land/partners"
                                className={styles.subtitle}
                            >
                                    Partners
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={styles.subtitle}
                                href="https://wemag.wecode.land/investors"
                            >
                                    Investors
                            </a>
                        </li>
                    </ul>
                </div>

                <div className={styles.column}>
                    <span className={styles.title}>More</span>
                    
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={styles.subtitle}
                                href="https://wemag.wecode.land/terms"
                            >
                                WeCode Terms
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={styles.subtitle}
                                href="https://wemag.wecode.land/privacy-policy"
                            >
                                Privacy
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://wemag.wecode.land/career"
                                className={styles.subtitle}
                            >
                                Join WeCode
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.footer_bottom}>
                <span className={styles.footer_bottom_text}>
                    © Copyright {new Date().getFullYear()} WeCode, Inc
                </span>

                <div className={styles.footer_bottom_social}>
                    <a href="https://www.facebook.com/wecode.land" target="_blank" rel="noreferrer">
                        <img
                            src="/images/icons/facebook.svg"
                            alt="Facebook"
                            width={24}
                            height={24}
                        />
                    </a>
                    <a href="https://www.linkedin.com/company/wecodeland" target="_blank" rel="noreferrer">
                        <img
                            src="/images/icons/linkedin.svg"
                            alt="LinkedIn"
                            width={24}
                            height={24}
                        />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;