import axiosInstance from "../config/api";
import axios from "axios";

export interface Session {
    title: string;
    price: number;
    start: Date;
    end: Date;
    SessionImage: object;
    status: string;
    completeness?: string;
    startTime?: Date;
    endTime?: Date;
    duration: number;
    description?: string;
    prerequisite?: any[];
    subscribedNumber: number;
    subscribedPercent: number;
    nbrStillAvailable: number;
    minPlace: number;
    maxPlace: number;
    TimeWindowForCancel: number;
    deleted: boolean;
    discount: number;
    difficulty?: string;
    rating: number;
    ratingsCount: number;
    domains?: string[];
    maxParticipants: number;
    isOnline: boolean;
    onlineSessionLink?: string;
    everyDay: number;
    scheduleData: object;
    category?: string;
    contributorsId?: string[];
    level?: string;
    studentsId?: string[];
    sessionsId?: string[];
    courseId?: string;
    coursePhoto?: object;
    syllabus?: object;
    sessionBooking?: any[];
    tutors?: any;
}

export async function getMySessions(id: string, limit: number, skip: number, tab: number): Promise<any | null> {
    // Actual code
    // const token = localStorage.getItem("token");
    // try {
    //     const response = await axiosInstance.get(
    //         `/Sessions/mySessions/${id}?limit=${limit}&skip=${skip}&tab=${tab}&id=${id}&access_token=${token}`        
    //     );
    //     return response.data;
    // } catch (error) {
    //     console.log(error);
    //     return null;
    // }

    // For testing 
    const token = "VI7ElNJawvOid2VIQ00P981JyYFA6lV3FEgJPfx0PRsHOWboii7JEDgpFLOGXjPY";
    id  = "5f22b026bd2c0611d85722d5";
    try {
        const response = await 
        axios.create({
            baseURL: "https://api.wecode.land/server/api",
            timeout: 60000,
            }).get(
        `/Sessions/mySessions/${id}?limit=${limit}&skip=${skip}&tab=${tab}&id=${id}&access_token=${token}`        
    );
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getListSessions(id: string, limit: number, skip: number, tab: number): Promise<any | null> {
    // Actual code
    // const token = localStorage.getItem("token");
    // try {
    //     const response = await axiosInstance.get(
    //         `/Sessions/getListSessions/${id}?limit=${limit}&skip=${skip}&tab=${tab}&id=${id}&access_token=${token}`        
    //     );
    //     return response.data;
    // } catch (error) {
    //     console.log(error);
    //     return null;
    // }

    // For testing 
    const token = "VI7ElNJawvOid2VIQ00P981JyYFA6lV3FEgJPfx0PRsHOWboii7JEDgpFLOGXjPY";
    id  = "5f22b026bd2c0611d85722d5";
    try {
        const response = await 
        axios.create({
            baseURL: "https://api.wecode.land/server/api",
            timeout: 60000,
            }).get(
        `/Sessions/getListSessions/${id}?limit=${limit}&skip=${skip}&tab=${tab}&id=${id}&access_token=${token}`        
    );
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}
