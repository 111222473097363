import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "../../../../components";
import { useStore } from "../../../../store";
import { ToastContainer, toast } from "react-toastify";

export default function SocialMedia() {
  const navigate = useNavigate();
  const { userStore } = useStore();
  const user = userStore.user;

  const [facebook, setFacebook] = useState<string>(user?.facebookUrl || "");
  const [instagram, setInstagram] = useState<string>(user?.instagramUrl || "");
  const [linkedIn, setLinkedIn] = useState<string>(user?.linkedInUrl || "");
  const [github, setGithub] = useState<string>(user?.githubUrl || "");

  const handleSave = async () => {
    const updatedUser = {
      facebookUrl: facebook,
      instagramUrl: instagram,
      linkedInUrl: linkedIn,
      githubUrl: github,
    };

    await userStore
      .updateProfile(user.id, updatedUser)
      .then(() => {
        toast.success("Profile Updated Successfully");
      })
      .catch((err) => {
        toast.error("Error Updating Profile");
        console.log(err);
      });
  };

  return (
    <form className="social-media">
      <ToastContainer theme="dark" />
      <div className="inputs">
        <div className="left-column">
          <div className="field">
            <label htmlFor="facebook">Facebook</label>
            <div className="input-field">
              <div className="input">
                <Input
                  type="text"
                  label="Copy link here"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="field">
            <label htmlFor="instagram">Instagram</label>
            <div className="input-field">
              <div className="input">
                <Input
                  type="text"
                  label="Copy link here"
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="field">
            <label htmlFor="linkedIn">LinkedIn</label>
            <div className="input-field">
              <div className="input">
                <Input
                  type="text"
                  label="Copy link here"
                  value={linkedIn}
                  onChange={(e) => setLinkedIn(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="field">
            <label htmlFor="github">Github</label>
            <div className="input-field">
              <div className="input">
                <Input
                  type="text"
                  label="Copy link here"
                  value={github}
                  onChange={(e) => setGithub(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="right-column"></div>
      </div>

      <div className="buttons">
        <Button
          text="Cancel"
          type="primary"
          customColor="#373737"
          width="400px"
          preventDefault={true}
          onClick={() => navigate("/")}
        />
        <Button
          text="Save"
          type="primary"
          width="400px"
          preventDefault={true}
          onClick={handleSave}
        />
      </div>
    </form>
  );
}
