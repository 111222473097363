import { useEffect, useState } from "react";
import Table from "../../../components/others/Table";
import { getMySessions, Session } from "src/store/session";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../../store";
import { Navbar, Loading } from "src/components/shared";
import SessionRows from "./Rows";

export default function MySessions() {
    const navigate = useNavigate();
    const { userStore } = useStore();

    useEffect(() => {
        userStore.getUser().then(() => {
            const user = userStore.user;
            if (!user) {
                navigate("/login");
            }
        });
    }, []);
  
    const user = userStore.user;

    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [sessions, setSessions] = useState<Session[] | null>(null);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const itemsPerPage = 5;
    const numberOfPages = count > 0 ? Math.ceil(count / itemsPerPage) : 1;

    useEffect(() => {
        setSessions(null);
        setCount(0);
        setLoading(true);
        
        getMySessions(user?.id, itemsPerPage, itemsPerPage * (page - 1), activeTab + 1)
          .then((response) => {
            if (response !== null) {
              console.log("response", response);
              setSessions(Object.entries(response)[0][1] as Session[]);
              setCount(Object.entries(response)[1][1] as number);
              setLoading(false);
            }
          }).catch((error) => {
            console.log(error);
          });
    }, [page, activeTab]);    

    const links = [
        { path: "/myprofile", label: "My Profile" },
    ];

    const tabs = [
        {
            name: "My ongoing Sessions",
            title: "Sessions management",
            columns: ["Session name", "Tutors", "Classroom", "Level", "From-To", ""],
        },
        {
            name: "My upcoming Sessions",
            title: "Sessions management",
            columns: ["Session name", "Tutors", "Classroom", "Level", "From-To", "Your rating", ""],
        },
        {
            name: "My completed Sessions",
            title: "Sessions management",
            columns: ["Session name", "Tutors", "Classroom", "Level", "From-To", "Your rating", ""],
        },
    ]

    const handleTabClick = (tab: number) => {
        setPage(1);
        setActiveTab(tab);
    };

    const handlePreviousPage = () => {
        if (page > 1) {
          setPage(page - 1);
        }
    }
    
    const handleNextPage = () => {
        if (page < numberOfPages) {
            setPage(page + 1);
        }
    }

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <>
            <Navbar />

            <div className="table-page-container">
                <div className="links">
                    {links.map((link, index) => (
                        <div className="link" key={index}>
                            <Link to={link.path} className="label">
                                {link.label}
                            </Link>
                            <div className="arrow">&gt;</div>
                        </div>
                    ))}
                    <div className="active label">
                        My Sessions
                    </div>
                </div>

                <div className="header">
                    <div className="tabs">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`tab ${activeTab === index ? "active" : ""}`}
                                onClick={() => handleTabClick(index)}
                            >
                                {tab.name}
                            </div>
                        ))}
                    </div>

                    {/* {button && <Button 
                        type="primary"
                        width="150px"
                        text={buttonText}
                    />} */}
                </div>

                <Table 
                    title={tabs[activeTab].title}
                    columns={tabs[activeTab].columns}
                    data={sessions}
                    page={page}
                    numberOfPages={numberOfPages}
                    count={count}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                    Rows={SessionRows as React.ComponentType<any>}
                />
            </div>
        </>
    );
}