import React from "react";

import styles from "./topics.module.css";
import TopicItem from "./TopicItem";

const Topics: React.FC = () => {
    return (
        <div className={styles.topics_container}>
            <div className={styles.topics}>
                <div className={styles.topics_heading}>
                    <span>Do you want to have more details about our categories ?</span>
                    <h2>A wide range of categories customised for you</h2>
                </div>

                <div className={styles.topics_list}>
                    <TopicItem
                        title="User Experience"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                        euismod sit elementum."
                        bgColor="#F4EBFF"
                        icon="/images/icons/user-experience.svg"
                    />

                    <TopicItem
                        title="Web Development"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                        euismod sit elementum."
                        bgColor="#E0EAFF"
                        icon="/images/icons/development.svg"
                    />

                    <TopicItem
                        title="Marketing"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                        euismod sit elementum."
                        bgColor="#FCE7F6"
                        icon="/images/icons/marketing.svg"
                    />
                </div>
            </div>
        </div>
    );
};

export default Topics;