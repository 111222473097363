import React from "react";

import styles from "./steps.module.css";
import StepItem from "./StepItem";

const Steps: React.FC = () => {
    return (
        <div className={styles.steps_container}>
            <div className={styles.steps}>
                <h2>How it works?</h2>

                <div className={styles.steps_list}>
                    <StepItem
                        bgColor="#F4EBFF"
                        icon="/images/icons/guru.svg"
                        title="Register for a course"
                        subTitle={""}
                    />

                    <StepItem
                        bgColor="#E0EAFF"
                        icon="/images/icons/certificate.svg"
                        title="Get a Course Certificate"
                        subTitle="All our courses are certified. The certificate will approve that you have completed the course."
                    />

                    <StepItem
                        bgColor="#FCE7F6"
                        icon="/images/icons/3d-cube.svg"
                        title="Advance your career"
                        subTitle="Improving your skills will help you gain new opportunities and have different positions."
                    />
                </div>
            </div>
        </div>
    );
};

export default Steps;